import * as React from "react";
import BannerCanceled from "~/components/BannerCanceled";
import BannerPastDue from "~/components/BannerPastDue";
import BannerSuspended from "~/components/BannerSuspended";
import BannerTrialWarning from "~/components/BannerTrialWarning";
import useStores from "~/hooks/useStores";

export default function SubscriptionBanners() {
  const { ui, auth } = useStores();
  const { team } = auth;

  const style = ui.sidebarCollapsed
    ? undefined
    : { marginLeft: `${ui.sidebarWidth}px` };

  if (!team) {
    return null;
  }

  return (
    <>
      {team.isCanceled ? (
        <BannerCanceled sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : team.isSuspended ? (
        <BannerSuspended sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : team.isPastDue ? (
        <BannerPastDue sidebarCollapsed={ui.sidebarCollapsed} style={style} />
      ) : null}
      {team.isNearTrialEnd && (
        <BannerTrialWarning
          sidebarCollapsed={ui.sidebarCollapsed}
          daysLeftInTrial={team.daysLeftInTrial}
          style={style}
        />
      )}
    </>
  );
}
