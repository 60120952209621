import * as React from "react";
import { Link } from "react-router-dom";
import Banner from "~/components/Banner";

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
  daysLeftInTrial: number;
};

function BannerTrialWarning({ daysLeftInTrial, ...rest }: Props) {
  return (
    <Banner {...rest}>
      Your free trial is almost over, there{" "}
      {daysLeftInTrial === 1
        ? "is just 1 day"
        : `are just ${daysLeftInTrial} days`}{" "}
      left – please <Link to="/settings/billing">add payment details</Link> to
      avoid interruption for your team.
    </Banner>
  );
}

export default BannerTrialWarning;
