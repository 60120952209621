import * as React from "react";
import { Link } from "react-router-dom";
import Banner from "~/components/Banner";

type Props = React.ComponentProps<typeof Banner> & {
  className?: string;
};

function BannerPastDue(props: Props) {
  return (
    <Banner {...props}>
      Your subscription payment failed, documents will soon become read-only.
      Please <Link to="/settings/billing">update your payment details</Link> to
      ensure no service interruption.
    </Banner>
  );
}

export default BannerPastDue;
